import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Paper, 
  Grid,
  FormControlLabel,
  Switch,
  Snackbar,
  Alert
} from '@mui/material';

const AUTH_CONFIG = {
  API_URL: process.env.REACT_APP_API_URL,
  TOKEN_KEY: 'authToken',
  USER_KEYS: ['username', 'fullname', 'role']
};

const ProfileEditPage = () => {
  const [userData, setUserData] = useState({
    username: '',
    role: '',
    tel: '',
    sms_enabled: false
  });
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Create axios instance with useMemo
  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: AUTH_CONFIG.API_URL,
    });

    // Add request interceptor to include token
    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem(AUTH_CONFIG.TOKEN_KEY);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    return instance;
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem(AUTH_CONFIG.TOKEN_KEY);
        
        // Verify token first
        await api.get(`/verify-token?token=${token}`);

        // Fetch user profile
        const response = await api.get('/users/me');

        setUserData({
          username: response.data.username,
          role: response.data.role,
          tel: response.data.tel.toString(),
          sms_enabled: response.data.sms_enabled
        });
      } catch (err) {
        setError(err.response?.data?.detail || 'Failed to fetch profile');
        localStorage.removeItem(AUTH_CONFIG.TOKEN_KEY);
      }
    };

    fetchUserProfile();
  }, [api]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const updatePayload = {
        tel: userData.tel ? parseInt(userData.tel) : undefined,
        sms_enabled: userData.sms_enabled,
        ...(password && { password })
      };

      await api.patch(`/users/${userData.username}`, updatePayload);

      setSuccess('Profile updated successfully');
      setOpenSnackbar(true);
      setPassword('');
    } catch (err) {
      setError(err.response?.data?.detail || 'Update failed');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom align="center">
          Edit Profile
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                value={userData.username}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Role"
                value={userData.role}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                value={userData.tel}
                onChange={(e) => setUserData(prev => ({
                  ...prev, 
                  tel: e.target.value
                }))}
                variant="outlined"
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={userData.sms_enabled}
                    onChange={(e) => setUserData(prev => ({
                      ...prev,
                      sms_enabled: e.target.checked
                    }))}
                  />
                }
                label="Enable SMS Notifications"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="New Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                helperText="Leave blank to keep current password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                fullWidth 
                type="submit" 
                variant="contained" 
                color="primary"
              >
                Update Profile
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setOpenSnackbar(false)} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {success}
        </Alert>
      </Snackbar>

      {error && (
        <Snackbar 
          open={!!error} 
          autoHideDuration={6000} 
          onClose={() => setError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setError('')} 
            severity="error" 
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default ProfileEditPage;