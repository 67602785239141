import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumberForDisplay } from "../../utils/FormatNumber";
import {
  Prix,
  Total_cmd,Prix_Moyen
} from "../../utils/Calculation";

const API_URL = process.env.REACT_APP_API_URL;

let exportedData = [];

const CommandStat = ({
  id_navire,
  nb_contrat_n,
  total_n,
  prix_cible,
  reloadTrigger,  
  productCoefficient_c=1

}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Total, setTotal] = useState(0);
  const [combinedTotal, setcombinedTotal] = useState(0);
  const [nb_contrat, setnb_contrat] = useState(0);
  const [totalContracts, settotalContracts] = useState(0);
  const [pm, setpm] = useState(0);
  const [combinedPrixMoyen, setcombinedPrixMoyen] = useState(0);
  const [diff, setdiff] = useState(0);
  const [adjustedDiff, setadjustedDiff] = useState(0);
  const [highlightRow, setHighlightRow] = useState(false); // State for row highlight

  useEffect(() => {
    if (id_navire) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/commands_stat?id_navire=${id_navire}&status=20`
          );
          setnb_contrat(0);
          setTotal(0);
          setpm(0);
          setdiff(0);

          if (response.data[0]) {
            exportedData = response.data[0]; // Store data in the exported variable

            setnb_contrat(exportedData.nb_contrat_sum || 0);
            setTotal(exportedData.total_sum  || 0);
            setpm(exportedData.pm  || 0);
            setdiff(exportedData.diff * productCoefficient_c || 0);
          }
        } catch (error) {
          setError("Erreur lors de la récupération des données");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [id_navire, reloadTrigger,productCoefficient_c]);

  useEffect(() => {
    // Highlight the row for 1 second when data changes
    setHighlightRow(true);
    setTimeout(() => setHighlightRow(false), 1000);
  }, [total_n]);

  useEffect(() => {
    settotalContracts(nb_contrat + nb_contrat_n) ;
    setcombinedTotal(Total + total_n);
    setcombinedPrixMoyen((combinedTotal / totalContracts /  productCoefficient_c) || 0)
    const prix_un = total_n / nb_contrat_n || 0;
    const newDiff = (prix_cible * productCoefficient_c - prix_un) * nb_contrat_n;
    setadjustedDiff(diff+ newDiff);
    
  }, [
    nb_contrat,
    nb_contrat_n,
    Total,total_n,
    combinedTotal,
    totalContracts,
    productCoefficient_c,
    diff,
    prix_cible
  ]);

  if (error) return <p>{error}</p>;

  
  

  return (
    <Box>
      <TableContainer component={Paper}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            padding: "5px",
            textAlign: "start",
          }}
        >
          Statistiques d'Achats et Estimations
        </Typography>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Contrats</TableCell>
              <TableCell>P. moyen</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Historique</TableCell>
              <TableCell>{nb_contrat }</TableCell>
              <TableCell>{formatNumberForDisplay(pm /  productCoefficient_c)}</TableCell>
              <TableCell
                sx={{
                  textAlign: "start",
                  color: diff >= 0 ? "#2e7d32" : "#d32f2f",
                }}
              >
                {formatNumberForDisplay(Total)} ({formatNumberForDisplay(diff)})
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>{totalContracts}</TableCell>
              <TableCell>{formatNumberForDisplay(combinedPrixMoyen)}</TableCell>
              <TableCell
                sx={{
                  textAlign: "start",
                  color: adjustedDiff >= 0 ? "#2e7d32" : "#d32f2f",
                  width: { md: 200 },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: highlightRow ? "#d6eaf8" : "transparent",
                    transition: "background-color 1s ease",
                  }}
                >
                  {formatNumberForDisplay(combinedTotal)} (
                  {formatNumberForDisplay(adjustedDiff)})
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export { exportedData };
export default CommandStat;
