import { useCallback, useEffect, useState } from "react";
import axios from "axios";

export const useFetchData = (url) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const fetchData = useCallback(async () => {
      setLoading(true);
      try {
        const response = await axios.get(url);
        setData(response.data);
        setError(null);
      } catch (err) {
        setError("Échec de la récupération des données");
      } finally {
        setLoading(false);
      }
    }, [url]);
    useEffect(() => {
      fetchData();
    }, [fetchData]);
    return { data, loading, error, fetchData };
  };
