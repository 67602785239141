import  { useState,useContext } from "react";
import useWebSocket from "../../hooks/useWebSocket";

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Grid from "@mui/material/Grid2";
import ConditionManager from "./ConditionComponent";
import { useFetchData } from "../../utils/useFetchData";
import { AuthContext } from "../../authentification/auth/AuthProvider";
import { formatDateForDisplay, formatDateForDisplay1 } from "../../utils/dateUtils";
const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL, // Replace with your FastAPI base URL
});

const conditions = [
  { value: 1, label: "Le prix augmente à" },
  { value: 2, label: "Le prix baisse à" },
];

const frequencies = [
  { value: 1, label: "une seule fois" },
  { value: 2, label: "une fois par heure" },
  { value: 3, label: "une fois par jour" },
];

const status_list = [
  { value: 20, label: "Actif" },
  { value: 22, label: "Déclenché" },
  { value: 90, label: "Non actif" },
  { value: 92, label: "Déclenché" },
];

const AlertManager = () => {
  const { username, role, fullname } = useContext(AuthContext);  

  const {
    data: alerts,
    loading: loadingAlerts,
    fetchData: fetchAlerts,
  } = useFetchData(`${API_URL}/alerts?created_by=${username}`);
  const { data: deliveries, loading: loadingDeliveries } = useFetchData(
    `${API_URL}/deliveries`
  );
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [formData, setFormData] = useState(initialFormState());
  const [formLoading, setFormLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [filter, setFilter] = useState("active"); // Default filter is 'active'

  const [selectedDeliveryCode, setSelectedDeliveryCode] = useState(""); // Add state for selected delivery code
  let latestData = useWebSocket(selectedDeliveryCode);
  latestData = latestData ? latestData.last : 0;

  function initialFormState() {
    return {
      delivery: "",
      condition: "",
      value: "",
      frequency: "",
      created_by:username,
      updated_by:username
    };
  }

  const handleAlertClick = (alert) => {
    setSelectedAlert(alert);
    setFormData({
      delivery: alert.delivery || "",
      condition: alert.condition || "",
      value: alert.value || "",
      frequency: alert.frequency || "",
      created_by:username,
      updated_by:username
    });
    setSelectedDeliveryCode(alert.delivery);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Handle the "delivery" case separately
    if (name === "delivery") {
      setSelectedDeliveryCode(value); // Set selected delivery code when delivery is selected
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    try {
      if (selectedAlert) {
        await api.put(`/alerts/${selectedAlert.id}`, formData);
        setSnackbar({
          open: true,
          message: "Alerte mise à jour avec succès",
          severity: "success",
        });
      } else {
        await api.post("/alerts", formData);
        setSnackbar({
          open: true,
          message: "Nouvelle alerte créée",
          severity: "success",
        });
      }
      fetchAlerts();
      clearForm();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Impossible d'enregistrer l'alerte",
        severity: "error",
      });
    } finally {
      setFormLoading(false);
    }
  };

  const clearForm = () => {
    setSelectedAlert(null);
    setFormData(initialFormState());
    setSelectedDeliveryCode(""); // Clear selected delivery code when form is cleared
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedAlert) {
      try {
        await api.delete(`/alerts/${selectedAlert.id}`);
        setSnackbar({
          open: true,
          message: "L'alerte a été supprimée avec succès",
          severity: "success",
        });
        fetchAlerts();
        clearForm();
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Échec de la suppression de l'alerte",
          severity: "error",
        });
      } finally {
        setDeleteDialogOpen(false);
      }
    }
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Filter alerts based on the selected filter
  const filteredAlerts = alerts.filter(
    (alert) =>
      (filter === "active" && alert.status < 90) ||
      (filter === "archived" && alert.status >= 90)
  );

  return (
    <Grid container spacing={1}>
      {/* Alert Table on the Left */}
      <Grid item size={{ md: 6, xs: 12 }}>
        <Box sx={{ height: "80vh" }}>
          <Paper sx={{ height: "100%", overflow: "hidden" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Liste des alertes
              </Typography>

              <Button
                size="small"
                onClick={clearForm}
                variant="contained"
                startIcon={<AddBoxIcon />}
              >
                Ajouter
              </Button>
              </Box>

              <Stack padding="5px" direction="row" spacing={1}>
                <Button
                  size="small"
                  variant={filter === "active" ? "contained" : "outlined"}
                  onClick={() => setFilter("active")}
                >
                  actives
                </Button>
                <Button
                  size="small"
                  variant={filter === "archived" ? "contained" : "outlined"}
                  onClick={() => setFilter("archived")}
                >
                  Historique
                </Button>
              </Stack>
              
            <Divider />

            {/* Alert Table */}
            <TableContainer sx={{ maxHeight: "100%", overflowY: "scroll" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  sx={{
                    display: "table-header-group",
                    "background-color": "rgb(250, 250, 250)",
                    "border-top": "1px solid #f0f0f0",
                    "border-bottom": "2px solid #f0f0f0",
                  }}
                >
                  <TableRow sx={{ "background-color": "rgb(250, 250, 250)" }}>
                    <TableCell>Date</TableCell>
                    <TableCell>Livraison</TableCell>
                    <TableCell>Condition</TableCell>
                    <TableCell>Fréquence</TableCell>
                    <TableCell>Statut</TableCell>
                  </TableRow>
                </TableHead>
                {loadingAlerts ? (
                  <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
                ) : filteredAlerts.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Aucun enregistrement trouvé
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableBody>
                    {filteredAlerts.map((alert) => (
                      <TableRow
                        key={alert.id}
                        hover
                        selected={
                          selectedAlert && selectedAlert.id === alert.id
                        }
                        onClick={() => handleAlertClick(alert)}
                        sx={{
                          backgroundColor:
                            selectedAlert && selectedAlert.id === alert.id
                              ? "#cfe8fc"
                              : "inherit",
                        }}
                      >
                        <TableCell>{formatDateForDisplay1(alert.created_at)}</TableCell>

                        {loadingDeliveries ? (
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          deliveries
                            .filter((d) => d.code === alert.delivery)
                            .map((delivery) => (
                              <TableCell
                                key={delivery.code}
                                value={delivery.code}
                              >
                                {delivery.name}
                              </TableCell>
                            ))
                        )}
                        <TableCell>
                          <ConditionManager
                            condi={alert.condition}
                            value={alert.value}
                          />
                        </TableCell>
                        <TableCell>
                          {frequencies.find(
                            (freq) => freq.value === alert.frequency
                          )?.label || "Unknown Frequency"}
                        </TableCell>
                        <TableCell>
                          {status_list.find(
                            (stat) => stat.value === alert.status
                          )?.label || "Unknown Frequency"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Grid>

      {/* Form to Edit or Create Alert on the Right */}
      <Grid item size={{ md: 6, xs: 12 }}>
        <Box sx={{ height: "80vh" }}>
          <Paper sx={{ height: "100%", overflow: "hidden" }}>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {selectedAlert ? `Modifier` : "Créer"}
                </Typography>

                <Stack direction="row" spacing={2}>
                  <Button
                    size="small"
                    color="error"
                    disabled={!selectedAlert}
                    onClick={handleDeleteClick}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    supprimer
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    enregistrer
                  </Button>
                </Stack>
              </Box>
              <Box padding="10px" sx={{ "& > :not(style)": { m: 1 } }}>
                <div>
                  <Box display="flex" alignItems="center">
                    <FormControl
                      sx={{ width: 300, marginRight: 2 }}
                      margin="normal"
                      size="small"
                    >
                      <InputLabel>Livraison</InputLabel>
                      <Select
                        label="delivery"
                        name="delivery"
                        value={formData.delivery}
                        onChange={handleFormChange}
                        required
                      >
                        {loadingDeliveries ? (
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          deliveries.map((delivery) => (
                            <MenuItem key={delivery.code} value={delivery.code}>
                              {delivery.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                    {selectedDeliveryCode && (
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {/* {latestData} */}
                      </Typography>
                    )}
                  </Box>

                  {/* Show the selected delivery code */}
                </div>
                <div>
                  <Box display="flex" alignItems="center">
                    <FormControl
                      fullWidth
                      margin="normal"
                      sx={{ width: 300, marginRight: 2 }}
                      size="small"
                    >
                      <InputLabel>Condition</InputLabel>
                      <Select
                        label="condition"
                        name="condition"
                        value={formData.condition}
                        onChange={handleFormChange}
                        required
                      >
                        {conditions.map((condition) => (
                          <MenuItem
                            key={condition.value}
                            value={condition.value}
                          >
                            {condition.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl margin="normal" size="small">
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Valeur
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        value={formData.value}
                        label="Amount"
                        name="value"
                        type="number"
                        onChange={handleFormChange}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                </div>
                <div>
                  <Box display="flex" alignItems="center">
                    <FormControl
                      sx={{ width: 300 }}
                      margin="normal"
                      size="small"
                    >
                      <InputLabel>Fréquence</InputLabel>
                      <Select
                        label="Fréquence"
                        name="frequency"
                        value={formData.frequency}
                        onChange={handleFormChange}
                        required
                      >
                        {frequencies.map((frequencies) => (
                          <MenuItem
                            key={frequencies.value}
                            value={frequencies.value}
                          >
                            {frequencies.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Box>
            </form>
          </Paper>
        </Box>
      </Grid>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Etes-vous sûr de vouloir confirmer la suppression?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AlertManager;
