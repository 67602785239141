export const formatToTwoDecimals = (value) => parseFloat(value.toFixed(2)) ;

export const Prix_Brute_C = (cours, prime) =>
  formatToTwoDecimals(cours + prime);

export const Prix_Net_C = (Prix_Brute_C, coefficient) =>
  formatToTwoDecimals(Prix_Brute_C * coefficient)  ;

export const Prix_Net_T = (Prix_Net_C, coefficient_c) =>
  formatToTwoDecimals(Prix_Net_C * coefficient_c)  ;


export const Prix = (cours, prime, coefficient = 1) =>
  formatToTwoDecimals((cours + prime)* coefficient)  ;


export const Total_cmd = (nb_contrat, Prix_Net_T) =>{
  return formatToTwoDecimals(nb_contrat * Prix_Net_T)  ;
}

export const Change = (cours, settlement) => {
  const change = formatToTwoDecimals(cours - settlement);

  if (settlement === 0) {
    return { change: 0, change_per: 0 };
  }

  return { change, change_per: (100 * change) / settlement };
};
 
export const Prix_Moyen = (nb_contrat, prix, coefficient_c = 1) =>
  formatToTwoDecimals(nb_contrat * prix * coefficient_c);