import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  Button,
  CircularProgress
} from '@mui/material';
import axios from "axios";
import { useFetchData } from '../utils/useFetchData';
const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL, // Replace with your FastAPI base URL
});

const UserManagementInterface = () => {
    const {
        data: users,
        loading: loadingUsers,
        fetchData: fetchUsers,
      } = useFetchData(`${API_URL}/users`);
      
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    role: '',
    tel: '',
    sms_enabled: false
  });
  const [loading, setLoading] = useState(false);

  


  const createUser = async () => {
    try {
      setLoading(true);
      await api.post("/users", newUser);
      
      setNewUser({
        username: '',
        password: '',
        role: '',
        tel: '',
        sms_enabled: false
      });
      fetchUsers();
    } catch (error) {
      console.error('Error creating user:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader title="User Management" />
      <CardContent>
        <div className="mb-4">
          <h2 className="text-lg font-medium mb-2">Create New User</h2>
          <div className="grid grid-cols-2 gap-4">
            <TextField
              label="Username"
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
            />
            <TextField
              label="Password"
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            />
            <TextField
              label="Role"
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            />
            <TextField
              label="Phone Number"
              value={newUser.tel}
              onChange={(e) => setNewUser({ ...newUser, tel: e.target.value })}
            />
            <div className="col-span-2 flex items-center">
              <Checkbox
                checked={newUser.sms_enabled}
                onChange={(e) => setNewUser({ ...newUser, sms_enabled: e.target.checked })}
              />
              <span>SMS Enabled</span>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-lg font-medium mb-2">Existing Users</h2>
          {loading ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>SMS Enabled</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.tel}</TableCell>
                      <TableCell>{user.sms_enabled ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={createUser}>Create User</Button>
      </CardActions>
    </Card>
  );
};

export default UserManagementInterface;