import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const AUTH_CONFIG = {
  API_URL: process.env.REACT_APP_API_URL,
  TOKEN_KEY: 'authToken',
  USER_KEYS: ['username', 'fullname', 'role']
};

export const AuthContext = createContext({
  isAuthenticated: false,
  username: '',
  fullname: '',
  role: '',
  login: async () => false,
  logout: () => {},
});

const clearAuthStorage = () => {
  AUTH_CONFIG.USER_KEYS.forEach(key => localStorage.removeItem(key));
  localStorage.removeItem(AUTH_CONFIG.TOKEN_KEY);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [fullname, setFullname] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: AUTH_CONFIG.API_URL,
    });

    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(AUTH_CONFIG.TOKEN_KEY);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return instance;
  }, []);

  const verifyToken = useCallback(async () => {
    const token = localStorage.getItem(AUTH_CONFIG.TOKEN_KEY);
    
    if (!token) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    }

    try {
      const response = await api.get(`/verify-token?token=${token}`);
      if (response.status === 200) {
        setIsAuthenticated(true);
        setUsername(localStorage.getItem('username') || '');
        setFullname(localStorage.getItem('fullname') || '');
        setRole(localStorage.getItem('role') || '');
      }
    } catch {
      clearAuthStorage();
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  const login = useCallback(async (username, password) => {
    try {
      const { data } = await api.post('/token', new URLSearchParams({ username, password }));
      
      if (data.access_token) {
        localStorage.setItem(AUTH_CONFIG.TOKEN_KEY, data.access_token);
        
        AUTH_CONFIG.USER_KEYS.forEach(key => {
          if (data[key]) localStorage.setItem(key, data[key]);
        });

        setIsAuthenticated(true);
        setUsername(data.username || '');
        setFullname(data.fullname || '');
        setRole(data.role || '');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  }, [api]);

  const logout = useCallback(() => {
    clearAuthStorage();
    setIsAuthenticated(false);
    setUsername('');
    setFullname('');
    setRole('');
  }, []);

  const contextValue = useMemo(() => ({
    isAuthenticated,
    username,
    fullname,
    role,
    login,
    logout
  }), [isAuthenticated, username, fullname, role, login, logout]);

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};