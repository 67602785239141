import React, { useContext } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom"; // For redirecting after logout
import { AuthContext } from "../../authentification/auth/AuthProvider";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";

const buttonData = [
  { label: "Navires", path: "/navires", icon: <DirectionsBoatFilledIcon /> },
  { label: "Commandes", path: "/commands", icon: <ReceiptLongIcon /> },
  { label: "Alertes", path: "/alertes", icon: <NotificationsIcon /> },
];

export default function MobileMenu() {
  const { username, role, fullname } = useContext(AuthContext);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const Sidebar = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
      logout(); // Call the logout function from AuthContext
      navigate("/login"); // Redirect to login page after logout
    };

    return (
      <List>
        <ListItem key="logout" disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Se déconnecter" />
          </ListItemButton>
        </ListItem>
      </List>
    );
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      <ListItem disablePadding><ListItemButton >{fullname}</ListItemButton></ListItem>
      <Divider />
      {buttonData.map(({ label, path, icon }) => (
          <ListItem key={path} disablePadding>
            <ListItemButton
              component={Link}
              to={path}
              onClick={toggleDrawer(anchor, false)} // Close menu on click
              sx={{
                width: "100%", // Full width buttons on mobile
                justifyContent: "center", // Center the text
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />

      <Sidebar />
    </Box>
  );

  return (
    <Box sx={{ display: { md: "none" } }}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton>
            <MenuIcon onClick={toggleDrawer(anchor, true)} />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </Box>
  );
}
