import React, { useState, useContext , useEffect} from "react";
import { AuthContext } from "../auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import LogoImage from "../../assets/LOGO-LRB.png";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const { login } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const success = await login(credentials.username.toLowerCase(), credentials.password);
      if (success) {
        navigate("/navires");
        setLoading(false);

      } else {
        setError("Nom d'utilisateur ou mot de passe invalide");
        setLoading(false);
      }
    } catch (err) {
      setError("Échec de la connexion. Veuillez réessayer.");
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "RoseAgri - Login";
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
        padding: { xs: 2, sm: 4 }, // Responsive padding
      }}
    >
      <Box textAlign="center" mb={2}>
        <img src={LogoImage} alt="Logo" style={{ height: "40px" }} />
      </Box>
      <Grid item size={{ xs: 12, sm: 8, md: 5, lg: 4 }}>
        {" "}
        {/* Responsive grid width */}
        <Card sx={{ borderRadius: 2, boxShadow: 3, padding: { xs: 2, sm: 3 } }}>
          {" "}
          {/* Responsive padding */}
          <Typography variant="h6" align="center" gutterBottom>
            Connectez-vous
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nom d'utilisateur"
              name="username"
              fullWidth
              variant="outlined"
              margin="normal"
              value={credentials.username}
              onChange={handleInputChange}
              required
              sx={{ borderRadius: 2 }}
            />
            <TextField
              label="Mot de passe"
              type="password"
              name="password"
              fullWidth
              variant="outlined"
              margin="normal"
              value={credentials.password}
              onChange={handleInputChange}
              required
              sx={{ borderRadius: 2 }}
            />
            {error && (
              <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
                {error}
              </Alert>
            )}
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 3,
                  py: 1.5,
                  fontSize: "1rem",
                  borderRadius: 2,
                  backgroundColor: "#1877f2",
                }}
                disabled = {loading}
              >
                Se connecter
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color:"primary",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
