// marketTypes.js

import { Chip } from "@mui/material";

const command_status_text = [
    { value: 10, label: <Chip label="En cours" color="warning" size="small" /> },
    { value: 20, label: <Chip label="Validée" color="success" size="small" /> },
    { value: 90, label: <Chip label="Annulée" color="inherit" size="small" /> },
  ];
  
  export default command_status_text;
  