import React, { useContext } from "react";
import { AuthContext } from "../../authentification/auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

export default function Sidebar () {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleLogout = () => {
      logout(); // Call the logout function from AuthContext
      navigate("/login"); // Redirect to login page after logout
    };
    const { username, role, fullname } = useContext(AuthContext);
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    return (
      <Box sx={{ flexGrow: 0, display: { xs: "none", md: "block" } }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} >
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px"}}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
          
        >
          <MenuItem >
          <Typography sx={{ textAlign: "start" }}>{fullname}</Typography>
          </MenuItem>
          <Divider></Divider>
          <MenuItem onClick={handleLogout}>
            <Typography sx={{ textAlign: "center" }}>Se déconnecter</Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };