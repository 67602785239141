import { useEffect, useState } from "react";
const WS_URL = process.env.REACT_APP_WS_URL_Data_Live;

const useWebSocket = (deliveryId = null) => {
  const [latestData, setLatestData] = useState(null);

  useEffect(() => {
    const socket = new WebSocket(`${WS_URL}/ws/data_live`);

    socket.onmessage = (event) => {
      const newData = JSON.parse(event.data);

      // Check if deliveryId is provided and handle accordingly
      if (deliveryId) {
        // If the data relates to a specific delivery (not applicable in the new format)
        // Since there is no array, we might want to handle it differently
        // For example, you can set latestData directly if it matches a condition
        if (newData.quote === deliveryId) {
          setLatestData(newData);
        }
      } else {
        // Set the latest data directly since no specific delivery ID is provided
        setLatestData(newData);
      }
    };

    return () => {
      socket.close();
    };
  }, [deliveryId]);

  return latestData;
};

export default useWebSocket;
