import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles"; // Import MUI theme hook
import { useMemo } from "react";

const buttonData = [
  { label: "Navires", path: "/navires" },
  { label: "Commandes", path: "/commands" },
  { label: "Alertes", path: "/alertes" },
];

export default function Pages() {
  const location = useLocation();
  const theme = useTheme(); // Get the MUI theme to access primary color

  const currentPath = location.pathname; // Store the current path to avoid multiple accesses

  const buttonStyles = useMemo(() => {
    return buttonData.reduce((acc, { path }) => {
      const isActive = currentPath === path; // Check if the path is active once
      acc[path] = {
        borderBottom: isActive ? `2px solid ${theme.palette.primary.main}` : "none",
        fontWeight: isActive ? "bold" : "normal",
        borderRadius: 0,
      };
      return acc;
    }, {});
  }, [currentPath, theme.palette.primary.main]); // Add text color to dependencies

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: "none", md: "flex" },
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: 300, display: "flex", justifyContent: "space-between" }}>
        {buttonData.map(({ label, path }) => (
          <Button
            key={path}
            component={Link}
            to={path}
            sx={buttonStyles[path]}
          >
            {label}
          </Button>
        ))}
      </Box>
    </Box>
  );
}