import { format, parseISO,parse, isMatch  } from 'date-fns';

// Display format: Convert DB date to "DD/MM/YYYY HH:mm:ss"
export const formatDateForDisplay = (dateString) => {
  const date = parseISO(dateString);
  return format(date, 'dd/MM/yyyy HH:mm:ss');
};

// Display format: Convert DB date to "DD/MM/YYYY HH:mm:ss"
export const formatDateForDisplay1 = (dateString) => {
  const date = parseISO(dateString);
  return format(date, 'dd/MM/yyyy');
};

// DB format: Convert "DD/MM/YYYY HH:mm:ss" or "DD/MM/YYYY" to DB format
export const formatDateForDatabase = (dateString) => {
  let date;
  
  // Check if dateString is in "DD/MM/YYYY" format
  if (isMatch(dateString, "dd/MM/yyyy")) {
    // Parse as "DD/MM/YYYY" and set time to 00:00:00.000
    date = parse(dateString, "dd/MM/yyyy", new Date());
    return format(date, "yyyy-MM-dd'T'00:00:00.000");
  }
  
  // Parse as "DD/MM/YYYY HH:mm:ss" if it includes time
  date = parse(dateString, "dd/MM/yyyy HH:mm:ss", new Date());
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};