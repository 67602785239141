import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function formatDate(isoDate) {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const CoursData = ({
  cours,
  cours_change,
  cours_change_per,
  prix,
  prix_change,
  prix_change_per,
  date = "01/01/2000 00:00:00",
}) => {
  return (
    <Card>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
          padding: "5px",
          textAlign: "start",
        }}
      >
        {"Cours au : "} {formatDate(date)}
      </Typography>

      <TableContainer component={Card}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Actuel</TableCell>
              <TableCell>Change</TableCell>
              <TableCell>Change %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Cours</TableCell>
              <TableCell>
                {cours.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "start",
                  color: cours_change >= 0 ? "#2e7d32" : "#d32f2f",
                }}
              >
                {cours_change.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "start",
                  color: cours_change >= 0 ? "#2e7d32" : "#d32f2f",
                }}
              >
                {cours_change_per.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                %
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Prix</TableCell>
              <TableCell>
                {prix.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "start",
                  color: cours_change >= 0 ? "#2e7d32" : "#d32f2f",
                }}
              >
                {prix_change.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "start",
                  color: cours_change >= 0 ? "#2e7d32" : "#d32f2f",
                }}
              >
                <Box
                  sx={{
                    transition: "background-color 1s ease",
                  }}
                >
                  {prix_change_per.toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  %
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CoursData;
