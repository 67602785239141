import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useFetchData } from "../../utils/useFetchData";
import {
  formatDateForDisplay1,
  formatDateForDatabase,
} from "../../utils/dateUtils";

const API_URL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: API_URL,
});

const NavireDetails = ({ id_navire, article }) => {
  const {
    data: deliveries,
    loading: loadingDeliveries,
    fetchData: fetchDeliveries,
  } = useFetchData(`${API_URL}/deliveries`);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [navireToDelete, setNavireToDelete] = useState(null);
  const [user_id, setuser_id] = useState(localStorage.getItem('user_id')); // Default filter is 'active'

  const [newNavire, setNewNavire] = useState({
    id_navire,
    datedeb: "",
    datefin: "",
    prime: 0,
    delivery: "",
    prix_cible: 0,
    created_by: user_id,
    updated_by: user_id,
  });
  const [editId, setEditId] = useState(null);
  const [editedNavire, setEditedNavire] = useState({});
  const [showNewRow, setShowNewRow] = useState(false);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const fetchData = useCallback(async () => {
    if (!id_navire) return;
    try {
      const [navireResponse, deliveriesResponse] = await Promise.all([
        api.get(`/navired?id_navire=${id_navire}`),
        fetchDeliveries(),
      ]);
      const formattedData = navireResponse.data.map((item) => ({
        ...item,
        datedeb: formatDateForDisplay1(item.datedeb),
        datefin: formatDateForDisplay1(item.datefin),
      }));
      setData(formattedData);
      setShowNewRow(formattedData.length === 0);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [id_navire, fetchDeliveries]);

  useEffect(() => {
    fetchData();
    setNewNavire((prev) => ({ ...prev, id_navire }));
  }, [id_navire, fetchData]);

  const handleDelete = async () => {
    try {
      await api.delete(`/navired/${navireToDelete}`);
      setSnackbar({
        open: true,
        message: "La ligne a été supprimée avec succès.",
        severity: "success",
      });
      setDeleteDialogOpen(false);
      fetchData();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Erreur lors de la suppression: ${error.message}`,
        severity: "error",
      });
    }
  };

  const openDeleteDialog = (navireId) => {
    setNavireToDelete(navireId);
    setDeleteDialogOpen(true);
  };

  const handleEdit = (navire) => {
    setEditId(navire.id);
    setEditedNavire(navire);
  };

  const handleSaveEdit = async () => {
    try {
      // Create a new navire object with updated values
      const editedNavireData = {
        ...editedNavire,
        id_navire,
        datedeb: formatDateForDatabase(editedNavire.datedeb),
        datefin: formatDateForDatabase(editedNavire.datefin),
      };

      await api.put(`/navired/${editId}`, editedNavireData);
      setSnackbar({
        open: true,
        message: "Ligne mis à jour avec succès",
        severity: "success",
      });
      setEditId(null);
      fetchData();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Erreur lors de la mise à jour: ${error.message}`,
        severity: "error",
      });
    }
  };

  const handleSaveNewRow = async () => {
    try {
      const newNavireData = {
        ...newNavire,
        id_navire,
        datedeb: formatDateForDatabase(newNavire.datedeb),
        datefin: formatDateForDatabase(newNavire.datefin),
      };
      await api.post("/navired", newNavireData);
      setSnackbar({
        open: true,
        message: "Ligne ajoutée avec succès",
        severity: "success",
      });
      setShowNewRow(false);
      fetchData();
      setNewNavire((prev) => ({
        ...prev,
        datedeb: "",
        datefin: "",
        prime: 0,
        delivery: "",
        prix_cible: 0,
      }));
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Erreur lors de l'ajout de la ligne: ${error.message}`,
        severity: "error",
      });
    }
  };

  if (error) return <p>{error}</p>;

  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography variant="h6" textAlign={"left"}>
          Détail Navire :
        </Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddBoxIcon />}
          disabled={!id_navire}
          onClick={() => setShowNewRow(true)}
        >
          Ajouter
        </Button>
      </Box>
      <Card>
        <CardContent>
          <TableContainer
            sx={{ minWidth: "100%", height: "220px", overflowY: "scroll" }}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {["Date début", "Date fin", "Livraison", "Prime", "Cible", "Action"].map((header, index) => (
                    <TableCell key={index} sx={{ padding: "5px", width: index === 2 ? "200px" : "100px" }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((navired) => (
                    
                    <TableRow key={navired.id} hover>
                      <TableCell sx={{ padding: "5px" }}>
                        {editId === navired.id ? (
                          <TextField
                            value={editedNavire.datedeb}
                    required
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                datedeb: e.target.value,
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12 }, // Change the font size as needed
                            }}
                          />
                        ) : (
                          <TextField
                            value={navired.datedeb}
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                datedeb: e.target.value,
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12 }, // Change the font size as needed
                            }}
                            disabled
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: "5px" }}>
                        {editId === navired.id ? (
                          <TextField
                            value={editedNavire.datefin}
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                datefin: e.target.value,
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12 }, // Change the font size as needed
                            }}
                          />
                        ) : (
                          <TextField
                            value={navired.datefin}
                            size="small"
                            inputProps={{
                              style: { fontSize: 12 }, // Change the font size as needed
                            }}
                            disabled
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: "5px", width: "200px" }}>
                        {editId === navired.id ? (
                          <Select
                            name="delivery"
                            value={editedNavire.delivery}
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                delivery: e.target.value,
                              })
                            }
                            size="small"
                            required
                            sx={{ fontSize: 12, width: "100%" }}
                          >
                            {loadingDeliveries ? (
                              <MenuItem disabled>
                                <CircularProgress size={24} />
                              </MenuItem>
                            ) : (
                              deliveries
                                .filter((d) => d.product === article)
                                .map((delivery) => (
                                  <MenuItem
                                    key={delivery.code}
                                    value={delivery.code}
                                    sx={{ fontSize: 12 }}
                                  >
                                    {delivery.name}
                                  </MenuItem>
                                ))
                            )}
                          </Select>
                        ) : (
                          <TextField
                            value={
                              deliveries
                                .filter(
                                  (product) => product.code === navired.delivery
                                )
                                .map((filteredProduct) => filteredProduct.name) // Mapping directly to name for TextField value
                            }
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                datefin: e.target.value,
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12, width: "100%" }, // Change the font size as needed
                            }}
                            disabled
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: "5px" }}>
                        {editId === navired.id ? (
                          <OutlinedInput
                            value={editedNavire.prime}
                            type="number"
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                prime: parseFloat(e.target.value),
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12, width: "100%" }, // Change the font size as needed
                            }}
                          />
                        ) : (
                          <TextField
                            value={formatNumber(navired.prime)}
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                datefin: e.target.value,
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12, width: "100%" }, // Change the font size as needed
                            }}
                            disabled
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: "5px" }}>
                        {editId === navired.id ? (
                          <OutlinedInput
                            value={editedNavire.prix_cible}
                            type="number"
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                prix_cible: parseFloat(e.target.value),
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12, width: "100%" }, // Change the font size as needed
                            }}
                          />
                        ) : (
                          <TextField
                            value={formatNumber(navired.prix_cible)}
                            size="small"
                            onChange={(e) =>
                              setEditedNavire({
                                ...editedNavire,
                                datefin: e.target.value,
                              })
                            }
                            inputProps={{
                              style: { fontSize: 12, width: "100%" }, // Change the font size as needed
                            }}
                            disabled
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: "5px" }}>
                        <Stack direction="row" spacing={1}>
                          {editId === navired.id ? (
                            <>
                              <IconButton
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleSaveEdit}
                              >
                                <SaveIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                variant="contained"
                                size="small"
                                onClick={() => setEditId(null)}
                              >
                                <CloseIcon
                                  fontSize="small"
                                  onClick={() => setShowNewRow(false)}
                                />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                color="default"
                                onClick={() => handleEdit(navired)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => openDeleteDialog(navired.id)}
                              >
                                <DeleteForeverIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
                {showNewRow && ( // Render new row for adding new navire
                  <TableRow>
                    <TableCell sx={{ padding: "5px" }}>
                      <TextField
                        value={newNavire.datedeb}
                        placeholder="DD/MM/AAAA"
                        name="datedeb"
                        size="small"
                        onChange={(e) =>
                          setNewNavire((prev) => ({
                            ...prev,
                            datedeb: e.target.value,
                          }))
                        }
                        inputProps={{
                          style: { fontSize: 12 }, // Change the font size as needed
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      <TextField
                        value={newNavire.datefin}
                        placeholder="DD/MM/AAAA"
                        name="datefin"
                        size="small"
                        onChange={(e) =>
                          setNewNavire((prev) => ({
                            ...prev,
                            datefin: e.target.value,
                          }))
                        }
                        inputProps={{
                          style: { fontSize: 12 }, // Change the font size as needed
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          name="delivery"
                          value={newNavire.delivery}
                          onChange={(e) =>
                            setNewNavire((prev) => ({
                              ...prev,
                              delivery: e.target.value,
                            }))
                          }
                          size="small"
                          required
                          sx={{ fontSize: 12 }}
                        >
                          {loadingDeliveries ? (
                            <MenuItem disabled>
                              <CircularProgress size={24} />
                            </MenuItem>
                          ) : (
                            deliveries
                              .filter((d) => d.product === article)
                              .map((delivery) => (
                                <MenuItem
                                  key={delivery.code}
                                  value={delivery.code}
                                  sx={{ fontSize: 12 }}
                                >
                                  {delivery.name}
                                </MenuItem>
                              ))
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      <OutlinedInput
                        value={newNavire.prime}
                        type="number"
                        size="small"
                        onChange={(e) =>
                          setNewNavire((prev) => ({
                            ...prev,
                            prime: e.target.value,
                          }))
                        }
                        inputProps={{
                          style: { width: 50, fontSize: 12 }, // Change the font size as needed
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      <OutlinedInput
                        value={newNavire.prix_cible}
                        type="number"
                        size="small"
                        onChange={(e) =>
                          setNewNavire((prev) => ({
                            ...prev,
                            prix_cible: e.target.value,
                          }))
                        }
                        inputProps={{
                          style: { width: 50, fontSize: 12 }, // Change the font size as needed
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleSaveNewRow}
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          size="small"
                          onClick={() => setEditId(null)}
                        >
                          <CloseIcon
                            fontSize="small"
                            onClick={() => setShowNewRow(false)}
                          />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Snackbar for notifications */}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Dialog for delete confirmation */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer cette ligne ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Annuler</Button>
          <Button onClick={handleDelete} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default NavireDetails;
