import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import useWebSocket from "../../hooks/useWebSocket";
import SignalIcon from "../NavBar/SignalIcon";

const Data = ({ deliveryId = null }) => {
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleString());
  const latestData = useWebSocket(deliveryId);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date().toLocaleString());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box style={{ color: "black", position: "relative" }}>
      <div>
        <p style={{ display: "flex", alignItems: "center" }}>
          <SignalIcon latestData={latestData} />
        </p>
      </div>
    </Box>
  );
};

export default Data;
