import React, { useEffect, ReactNode } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import packageJson from '../package.json'; // Chemin vers le fichier package.json

import NotificationList from "./components/Notifications/NotificationList";
import ResponsiveAppBar from "./components/NavBar/NavBarComponent";
import AlertComponent from "./components/Alertes/AlertComponent";
import NavireComponent from "./components/Navires/NavireComponent";
import CommandManager from "./components/command/CommandComponent";

import { AuthProvider } from "./authentification/auth/AuthProvider";
import PrivateRoute from "./authentification/auth/PrivateRoute";
import Login from "./authentification/pages/Login";
import Profile from "./authentification/pages/Profile";
import UserManagementInterface from "./authentification/UserComponenet";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#FFA500",
    },
  },
});

const privateRoutes = [
  { path: "/profile", component: <Profile />, title: "Profile" },
  { path: "/notifications", component: <NotificationList />, title: "Notifications" },
  { path: "/commands", component: <CommandManager />, title: "Commands" },
  { path: "/alertes", component: <AlertComponent />, title: "Alertes" },
  { path: "/navires", component: <NavireComponent />, title: "Navires" },
  { path: "/users", component: <UserManagementInterface />, title: "Users" },
];

// Define the prop types for PrivateRouteWrapper
interface PrivateRouteWrapperProps {
  component: ReactNode;
}

const PrivateRouteWrapper: React.FC<PrivateRouteWrapperProps> = ({ component }) => (
  <PrivateRoute>
    <Box sx={{ minHeight: "89vh", padding: "74px 10px 0 10px" }}>
      {component}
    </Box>
  </PrivateRoute>
);

function App() {

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <Box>
              <ResponsiveAppBar />
              <Box sx={{ backgroundColor: "#f0f2f5", flexGrow: 1 }}>
                {/* Use useLocation inside a component rendered by Routes */}
                <LocationAwareRoutes />
              </Box>
            </Box>
          </div>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

const LocationAwareRoutes = () => {
  const appName = packageJson.name; // Récupère le nom de l'app à partir de package.json

  const location = useLocation();

  useEffect(() => {
    // Set the document title based on the current route
    const currentRoute = privateRoutes.find(route => route.path === location.pathname);
    if (currentRoute) {
      document.title = `${currentRoute.title} - ${appName}`;
    } else {
      document.title = `Connexion - ${appName}`;
    }
  }, [location,appName]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {privateRoutes.map(({ path, component }) => (
        <Route
          key={path}
          path={path}
          element={<PrivateRouteWrapper component={component} />}
        />
      ))}
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default App;
