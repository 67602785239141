import { Typography } from "@mui/material";

import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";

const conditions = [
  {
    value: 1,
    label: (
      <>
        <TrendingUpRoundedIcon color="success" />{" "}
        <Typography component="span" sx={{ color: "green" }}>
        Le prix augmente à{" "}
        </Typography>{" "}
      </>
    ),
  },
  {
    value: 2,
    label: (
      <>
        <TrendingDownRoundedIcon color="error" />{" "}
        <Typography component="span" sx={{ color: "#d32f2f" }}>
        Le prix baisse à{" "}
        </Typography>{" "}
      </>
    ),
  },
];

const ConditionManager = ({ condi, value }) => {
  return (
    <Typography
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1, // Optional: adds space between the elements
      }}
      variant="body1"
      justifySelf="start"
    >
      {conditions.find((cond) => cond.value === condi)?.label ||
        "inconnu"}{" "}
      <Typography component="span">{value}</Typography>
    </Typography>
  );
};

export default ConditionManager;
